import React, { useRef, useState, useEffect } from "react"
import { StaticQuery, graphql,Link } from "gatsby"
import MenuItem from "../Menu/MenuItem"
import Social from "../Others/Social"
import SocialModal from "../Others/SocialModal"
import Logo from "../Others/Logo"
import LogoProyecto from "../Others/LogoProyecto"
import ReactModal from "react-modal"

const HEADER_QUERY = graphql`
  {
    wordpress {
      menuItems(where: {location: HCMS_MENU_HEADER}) {
        nodes {
          id
          label
          url
          title
          path
        }
      }
      generalSettings {
        url
      }
    }
  }
`;

const customStyles = {
  overlay : {
    backgroundColor: '#FFF6E1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'none',
    // padding: '0'
  },
  content:{
    width: '100%',
    inset: 'initial',
    position: 'relative',
    margin: 'auto',
    border: 'initial',
    backgroundColor: 'transparent',
    overflow: 'initial'
  }
};

const Header = ({classEle}) => {

  const header = useRef('');
  if((typeof window !== `undefined`)) window.addEventListener('scroll', ()=>{
    if(header.current){
      if(window.scrollY > 20) header.current.classList.add("isScroll")
      else header.current.classList.remove("isScroll")
    }
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  const handleModalOpen = () => {
    setIsModalOpen(true);
  }

  useEffect(() => {
    var URLactual = window.location.href;
    if(URLactual.indexOf("#sobre-labok") !== -1){
      const offsetTop = document.querySelector("#sobre-labok").offsetTop - 10;
      window.scroll({
        top: offsetTop,
        behavior: "smooth"
      })
    }
    if(URLactual.indexOf("#el-proyecto") !== -1){
      const offsetTop = document.querySelector("#el-proyecto").offsetTop - 20;
      window.scroll({
        top: offsetTop,
        behavior: "smooth"
      })
    }
    if(URLactual.indexOf("#galeria") !== -1){
      const offsetTop = document.querySelector("#galeria").offsetTop - 10;
      window.scroll({
        top: offsetTop,
        behavior: "smooth"
      })
    }
    if(URLactual.indexOf("#planos") !== -1){
      const offsetTop = document.querySelector("#planos").offsetTop - 10;
      window.scroll({
        top: offsetTop,
        behavior: "smooth"
      })
    }
    if(URLactual.indexOf("#ubicacion") !== -1){
      const offsetTop = document.querySelector("#ubicacion").offsetTop - 10;
      window.scroll({
        top: offsetTop,
        behavior: "smooth"
      })
    }
    if(URLactual.indexOf("#contacto") !== -1){
      const offsetTop = document.querySelector("#contacto").offsetTop - 10;
      window.scroll({
        top: offsetTop,
        behavior: "smooth"
      })
    }

  });

  return (

    <StaticQuery
      query={HEADER_QUERY}
      render={(data) =>{
        const menuItems = data.wordpress.menuItems.nodes;
        const wordPressUrl = data.wordpress.generalSettings.url;

        return(
          <>
            <header className={`header only-desk ${classEle}`} ref={header}>
                <nav className="nav-menu mxw-1760 mx-auto ">
                  <Link to="/">
                    <Logo/>
                  </Link>
                  <ul className="nav-menu__list shrink-0">
                    {
                      menuItems &&
                      menuItems.map((menuItem) => (
                        <MenuItem
                            key={menuItem.id}
                            menuItem={menuItem} wordPressUrl={wordPressUrl}
                            modal={setIsModalOpen}
                        />
                      )
                    )}
                    <li className="nav-menu__social">
                      <Social/>
                    </li>
                  </ul>
                </nav>
            </header>
            <header className="only-mov bg-orange-100 mov-pt-14 mov-pb-12 mov-px-16">
              <nav className="w-100">
                <ul className="flex justify-space-between">
                  <li>
                    <Link to="/">
                      <Logo/>
                    </Link>
                  </li>
                  <li>
                    <button onClick={handleModalOpen}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="2" fill="#FFF6E1"/>
                        <rect y="14" width="20" height="2" fill="#FFF6E1"/>
                        <rect y="7" width="20" height="2" fill="#FFF6E1"/>
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </header>
            <ReactModal
              isOpen={isModalOpen}
              onRequestClose={handleModalClose}
              contentLabel="Modal Menu"
              style={customStyles}
            >
              <div className="modal--menu">
                <header className=" header--modal mov-pt-14 mov-pb-12 mov-px-16">
                  <nav className="w-100">
                    <ul className="flex justify-space-between">
                      <li>
                        <Link to="/">
                          <Logo/>
                        </Link>
                      </li>
                      <li>
                        <button onClick={handleModalClose}>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L16.9982 17" stroke="#FF644D" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M1 17L16.9982 1" stroke="#FF644D" strokeWidth="2" strokeMiterlimit="10"/>
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </header>
                <div className="text-center mov-mt-46 mov-mb-50 menu-logo">
                  <LogoProyecto/>
                </div>
                <div className="mov-mb-30">
                  <ul className="nav-menu__list shrink-0 mov-pb-25 bb-2 mov-mx-50">
                    {
                      menuItems &&
                      menuItems.map((menuItem) => (
                          <MenuItem
                            key={menuItem.id}
                            menuItem={menuItem} wordPressUrl={wordPressUrl}
                            modal={setIsModalOpen}
                          />
                        )
                      )}
                  </ul>
                </div>
                <div className="text-center">
                  <SocialModal />
                </div>
              </div>
            </ReactModal>
          </>
        )
      }

      }
    />

  )
}

export default Header